import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Ran } from '../Utils/utils';
import { FabStyle } from '../Styles/styles';
import { controller_type_mapping, statusColors } from '../Constants/constants';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/Devices';
import PublicIcon from '@mui/icons-material/Public';

import getStateManager from '../StateManager.js';

const mm = {
  marginTop: 10
};

export default function ManagePage() {
  let { t } = useTranslation();

  const [open_add_simulation, setOpenAddSimulation] = React.useState(false);
  const openAddDialog = () => {
    setOpenAddSimulation(true);
  };
  const closeAddDialog = () => {
    setOpenAddSimulation(false);
  };

  const [open_delete_dialog, setOpenDeleteDialog] = React.useState(false);
  const [cur_controller_id, setCurControllerId] = React.useState('');
  const openDeleteDialog = (controller_id) => {
    setCurControllerId(controller_id);
    setOpenDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setCurControllerId('');
    setOpenDeleteDialog(false);
  };

  const stateManager = getStateManager();
  const controller = stateManager.getControllerById(cur_controller_id);

  return (
    <Container style={mm}>
      {(stateManager.simulation === undefined || stateManager.simulation === null) && (
        <Fab size="small" variant="extended" style={FabStyle} onClick={openAddDialog}>
          {' '}
          <AddIcon />{' '}
        </Fab>
      )}
      <AddDialog
        open={open_add_simulation}
        handleClose={closeAddDialog}
        wsManager={stateManager.wsManager}
      />
      <DeleteDialog
        open={open_delete_dialog}
        controller={controller}
        wsManager={stateManager.wsManager}
        handleClose={closeDeleteDialog}
        command="delete_controller"
      />
      <TableContainer component={Paper}>
        <Table aria-label="table of controllers">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>{t('type')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stateManager.controllers.map((controller) => (
              <TableRow key={controller.name}>
                <TableCell>
                  <CircleIcon
                    style={{ color: statusColors[controller?.connection?.status ?? 'bad'] }}
                  />
                </TableCell>
                <TableCell scope="row" component={Link} to={`/controller/${controller.id}`}>
                  {controller.name}
                </TableCell>
                <TableCell>{controller_type_mapping[controller.type]}</TableCell>
                <TableCell>
                  {controller.type === 6000 && (
                    <>
                      <Link to={`/controller/${controller.id}/zoneoverview`}>
                        <Button>
                          <PublicIcon style={{ marginRight: '10px' }} />
                          {t('overview')}
                        </Button>
                      </Link>

                      <Link to={`/controller/${controller.id}/slaves`}>
                        <Button>
                          <DevicesIcon style={{ marginRight: '10px' }} /> Slaves
                        </Button>
                      </Link>
                      <Link to={`/controller/${controller.id}/manage_zones`}>
                        <Button>
                          <AddIcon /> {t('manage_zones')}
                        </Button>
                      </Link>
                      <Link to={`/controller/${controller.id}/system`}>
                        <Button>
                          <SettingsIcon /> {t('system')}
                        </Button>
                      </Link>
                    </>
                  )}{' '}
                  {
                    <Button onClick={() => openDeleteDialog(controller.id)} color="secondary">
                      {t('delete')}
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

function AddDialog({ open, handleClose, wsManager }) {
  let { t } = useTranslation();
  const [values, setValues] = React.useState({ name: '', type: 5053 });
  const validateForm = (event) => {
    if (values.name) {
      //Add connection to websocket server
      const display_name = values.name;
      const internal_id = display_name.toLowerCase().replace(' ', '_');
      var json_request = {
        command: 'mock_initialize',
        cookie: document.cookie,
        internal_id: internal_id
      };
      json_request.data = {};
      json_request.data['display_name'] = display_name;
      json_request.data['type'] = parseInt(values.type);
      json_request.data['project_id'] = parseInt(values.type) === 5155 ? 0 : 0;
      const mac_address = Ran(0, 9) + '-' + Ran(0, 9) + '-' + Ran(0, 9) + '-' + Ran(9, 0);
      json_request.data['mac_address'] = mac_address;
      wsManager.socket.send(JSON.stringify(json_request));
      window.location.reload();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('add_simulation')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="Name"
          type="text"
          fullWidth
          onChange={(event) => setValues({ ...values, name: event.target.value })}
        />
        <Select
          fullWidth
          value={values.type}
          onChange={(event) => setValues({ ...values, type: event.target.value })}
        >
          {[...Object.entries(controller_type_mapping)].map(([key, value]) => (
            <MenuItem value={key} selected>
              {value}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={validateForm} color="primary">
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function DeleteDialog({ open, controller, wsManager, handleClose, command }) {
  let { t } = useTranslation();
  let { enqueSnackbar } = useSnackbar();
  const deleteController = (event) => {
    if (controller) {
      wsManager.sendWSMsg(controller, command);
      // setWSReq(CreateControllerWSRequest(wsRef.current, controller.id, command));
      handleClose();
    } else {
      enqueSnackbar('Conntollers or controller with id=' + controller.id + ' undefined.', {
        variant: 'error'
      });
      handleClose();
    }
  };

  if (controller) {
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t(command)}</DialogTitle>
        <DialogContent>
          {t(command + '_confirm')} <i>{controller.name}</i>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={deleteController} color="primary">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"></Dialog>;
  }
}
