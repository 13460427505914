export const info_values = {
  humidity: -1,
  temp: -1,
  vpd: -1,
  temp_leaf_current: -1,
  co2: -1,
  neg_pressure: -1,
  temp_heating_mat: -1,
  humidity_max: -1,
  humidity_min: -1,
  temp_max: -1,
  temp_min: -1,
  info_interval_timer_active: -1,
  info_interval_timer: -1,
  info_interval_2_timer_active: -1,
  info_interval_2_timer: -1,
  time: '--:--:--',
  date: 'yyyy.mm.dd',
  phase: -1,
  cur_day: -1,
  info_temp_sp: -1,
  info_humidity_sp: -1,
  temp_heater_setpoint_info: -1,
  info_lights: -1,
  rh_intake_current: -1,
  temp_intake_current: -1,
  ec_fans_intake_out: -1,
  ec_fans_intake_rpm: -1,
  ec_fans_exhaust_out: -1,
  ec_fans_exhaust_rpm: -1,
  out_count: 25,
  out_1_state: -1,
  out_2_state: -1,
  out_3_state: -1,
  out_4_state: -1,
  out_5_state: -1,
  out_6_state: -1,
  out_7_state: -1,
  out_8_state: -1,
  out_9_state: -1,
  out_10_state: -1,
  out_11_state: -1,
  out_12_state: -1,
  out_13_state: -1,
  out_14_state: -1,
  out_15_state: -1,
  out_16_state: -1,
  out_17_state: -1,
  out_18_state: -1,
  out_19_state: -1,
  out_20_state: -1,
  out_21_state: -1,
  out_22_state: -1,
  out_23_state: -1,
  out_24_state: -1,
  out_25_state: -1,
  humidity_graph: [],
  temp_graph: [],
  vol_water_content: -1,
  ec_root_zone: -1,
  temp_root_zone: -1,
  ec_nutrient_solution: -1,
  ph_nutrient_solution: -1,
  temp_nutrient_solution: -1,
  irrigation_state: -1,
  irrigation_phase_info: -1,
  irrigation_err: -1,
  hvac_status: -1,
  hvac_rh_info: -1,
  hvac_temp_info: -1,
  hvac_compress_status: -1,
  hvac_alarm_status: -1,
  hvac_info: -1,
  show_hvac_info: -1,
  hvac_status_2: -1,
  hvac_rh_info_2: -1,
  hvac_temp_info_2: -1,
  hvac_compress_status_2: -1,
  hvac_alarm_status_2: -1,
  hvac_info_2: -1,
  show_hvac_info_2: -1,
  hvac_status_3: -1,
  hvac_rh_info_3: -1,
  hvac_temp_info_3: -1,
  hvac_compress_status_3: -1,
  hvac_alarm_status_3: -1,
  hvac_info_3: -1,
  show_hvac_info_3: -1,
  slave_info_count: -1,
  slave_infos: []
};

export const info_io = {
  multi_info_ios: []
};

export const climate = {
  humidity_sp_day: -1,
  humidity_sp_night: -1,
  temp_sp_day: -1,
  temp_sp_night: -1,
  vpd_sp: -1,
  vpd_sp_night: -1,
  vpd_calc_enable: -1,
  temp_sp_heater_day: -1,
  temp_sp_heater_night: -1,

  toggle_temp_heating_mat_day: -1,
  temp_sp_heating_mat_day_rel: -1,
  temp_sp_heating_mat_day_abs: -1,

  toggle_temp_heating_mat_night: -1,
  temp_sp_heating_mat_night_rel: -1,
  temp_sp_heating_mat_night_abs: -1,
  dehumidification_mode: -1,
  heating_to_dehumidify_en: -1,

  preheat_night_day: -1
};
export const co2 = {
  co2_control: -1,
  co2_sp: -1,
  room_scale: -1,
  room_vol_sp: -1,
  co2_flow: -1,
  exhaust_fans_flow: -1,
  temp_sp_co2: -1,
  exhaust_fan_ec_max_co2: -1,
  exhaust_fan_ec_min_co2: -1,
  intake_fan_ec_max_co2: -1,
  intake_fan_ec_min_co2: -1,
  exhaust_fan_ac_max_co2: -1,
  exhaust_fan_ac_min_co2: -1,
  intake_fan_ac_max_co2: -1,
  intake_fan_ac_min_co2: -1,
  temp_heater_co2: -1
};
export const ventilation = {
  pressure_percent_day: -1,
  pressure_percent_night: -1,

  exhaust_fan_ec_min_day: -1,
  exhaust_fan_ec_max_day: -1,
  exhaust_fan_ec_min_night: -1,
  exhaust_fan_ec_max_night: -1,
  intake_fan_ec_max_day: -1,
  intake_fan_ec_min_day: -1,
  intake_fan_ec_max_night: -1,
  intake_fan_ec_min_night: -1,

  exhaust_fan_ac_min_day: -1,
  exhaust_fan_ac_max_day: -1,
  exhaust_fan_ac_min_night: -1,
  exhaust_fan_ac_max_night: -1,
  intake_fan_ac_max_day: -1,
  intake_fan_ac_min_day: -1,
  intake_fan_ac_max_night: -1,
  intake_fan_ac_min_night: -1,

  deviation_rpm_reduction: -1,
  pressure_pa: -1
};

export const timing = {
  controller_time: -1, // time_cont_hour/_min/_sec (hh:mm:ss)
  controller_date: -1, // time_cont_year/_month/_day (YYYY/mm/dd)
  start_day: -1, // start_day_hour/_min (hh:mm)
  end_day: -1, // end_day_hour/_min (hh:mm)
  interval_on_day: -1, // interval_on_day_hour/_min/_sec (time: hh:mm:ss)
  interval_off_day: -1, // interval_off_day_hour/_min/_sec (time: hh:mm:ss)
  interval_on_night: -1, // interval_on_night_hour/_min/_sec (time: hh:mm:ss)
  interval_off_night: -1, // interval_off_night_hour/_min/_sec (time: hh:mm:ss)
  event1_start0: -1, // event1_start_hour/_min (hh:mm)
  event1_duration0: -1, // event1_duration_hour/_min (hh:mm:ss)
  event2_start0: -1, // event1_start_hour/_min (hh:mm)
  event2_start: -1, // event2_start_hour/_min (hh:mm)
  event2_duration: -1, // event2_duration_hour/_min (hh:mm:ss)
  event3_start: -1, // event3_start_hour/_min (hh:mm)
  event3_duration: -1, // event3_duration_hour/_min (hh:mm:ss)
  event4_start: -1, // event4_start_hour/_min (hh:mm)
  event4_duration: -1, // event4_duration_hour/_min (hh:mm:ss)

  event5_start: -1, // event5_start_hour/_min (hh:mm)
  event5_duration: -1, // event5_duration_hour/_min (hh:mm:ss)
  event6_start: -1, // event6_start_hour/_min (hh:mm)
  event6_duration: -1, // event6_duration_hour/_min (hh:mm:ss)
  event7_start: -1, // event7_start_hour/_min (hh:mm)
  event7_duration: -1, // event7_duration_hour/_min (hh:mm:ss)
  event8_start: -1, // event8_start_hour/_min (hh:mm)
  event8_duration: -1, // event8_duration_hour/_min (hh:mm:ss)*/

  time_event1_exec_gap: -1,
  time_event2_exec_gap: -1,
  time_event3_exec_gap: -1,
  time_event4_exec_gap: -1,
  time_event5_exec_gap: -1,
  time_event6_exec_gap: -1,
  time_event7_exec_gap: -1,
  time_event8_exec_gap: -1,

  time_interval_2_on_day: -1,
  time_interval_2_off_day: -1,
  time_interval_2_on_night: -1,
  time_interval_2_off_night: -1
};

export const lights = {
  lights_max: -1,
  lights_min: -1,
  light_sunrise_duration: -1,
  lights_dim_over_temp_enable: -1,
  lights_dim_over_temp_temp_day: -1,
  lights_dim_over_temp_temp_co2: -1,
  lights_dim_over_temp_reduct_ph1: -1,
  lights_dim_over_temp_reduct_ph2: -1,
  lights_dim_over_temp_reduct_ph3: -1,
  lights_dim_over_temp_reduct_wait_minute: -1
};

export const system = {
  temp_format: -1,
  date_format: -1,
  language: -1,
  display_timeout: -1,
  fan_type_select: -1,
  multi_sensor_handling_rh: -1,
  multi_sensor_handling_temp: -1,
  multi_sensor_handling_co2: -1,
  multi_sensor_handling_pressure: -1,
  multi_sensor_handling_heating_mat: -1,
  multi_sensor_handling_leaf: -1,
  filter_val_sensor_co2: -1,
  action_transmit_filter_sensor: -1,
  calib_val_sensor_co2: -1,
  action_transmit_calib_filter_sensor_co2: -1,
  fw_version_number: '',
  esp32_firmware_name: 'unknown',
  pressure_auto_cal: -1
};

export const multi_out = {
  out_1_type: -1,
  out_1_memo: -1,
  out_1_func: -1,
  out_2_type: -1,
  out_2_memo: -1,
  out_2_func: -1,
  out_3_type: -1,
  out_3_memo: -1,
  out_3_func: -1,
  out_4_type: -1,
  out_4_memo: -1,
  out_4_func: -1,
  out_5_type: -1,
  out_5_memo: -1,
  out_5_func: -1,
  out_6_type: -1,
  out_6_memo: -1,
  out_6_func: -1,
  out_7_type: -1,
  out_7_memo: -1,
  out_7_func: -1,
  out_8_type: -1,
  out_8_memo: -1,
  out_8_func: -1,
  out_9_type: -1,
  out_9_memo: -1,
  out_9_func: -1,
  out_10_type: -1,
  out_10_memo: -1,
  out_10_func: -1,
  out_11_type: -1,
  out_11_memo: -1,
  out_11_func: -1,
  out_12_type: -1,
  out_12_memo: -1,
  out_12_func: -1,
  out_13_type: -1,
  out_13_memo: -1,
  out_13_func: -1,
  out_14_type: -1,
  out_14_memo: -1,
  out_14_func: -1,
  out_15_type: -1,
  out_15_memo: -1,
  out_15_func: -1,
  out_16_type: -1,
  out_16_memo: -1,
  out_16_func: -1,
  out_17_type: -1,
  out_17_memo: -1,
  out_17_func: -1,
  out_18_type: -1,
  out_18_memo: -1,
  out_18_func: -1,
  out_19_type: -1,
  out_19_memo: -1,
  out_19_func: -1,
  out_20_type: -1,
  out_20_memo: -1,
  out_20_func: -1,
  out_21_type: -1,
  out_21_memo: -1,
  out_21_func: -1,
  out_22_type: -1,
  out_22_memo: -1,
  out_22_func: -1,
  out_23_type: -1,
  out_23_memo: -1,
  out_23_func: -1,
  out_24_type: -1,
  out_24_memo: -1,
  out_24_func: -1,
  out_25_type: -1,
  out_25_memo: -1,
  out_25_func: -1
};

export const multi_io = {
  multi_multi_ios: []
};

export const alarms = {
  alarm_low_temperature_air_day_setting: -1,
  alarm_over_temperature_air_day_setting: -1,
  alarm_low_temperature_air_night_setting: -1,
  alarm_over_temperature_air_night_setting: -1,
  alarm_low_temperature_air_co2_setting: -1,
  alarm_over_temperature_air_co2_setting: -1,
  alarm_low_co2_concentration_setting: -1,
  alarm_over_co2_concentration_setting: -1,
  alarm_low_rh_day_setting: -1,
  alarm_over_rh_day_setting: -1,
  alarm_low_rh_night_setting: -1,
  alarm_over_rh_night_setting: -1,
  alarm_low_vpd_setting: -1,
  alarm_over_vpd_setting: -1,
  alarm_low_vpd_night_setting: -1,
  alarm_over_vpd_night_setting: -1,
  alarm_low_temperature_air_day_enable: -1,
  alarm_over_temperature_air_day_enable: -1,
  alarm_low_temperature_air_night_enable: -1,
  alarm_over_temperature_air_night_enable: -1,
  alarm_low_temperature_air_co2_enable: -1,
  alarm_over_temperature_air_co2_enable: -1,
  alarm_low_co2_concentration_enable: -1,
  alarm_over_co2_concentration_enable: -1,
  alarm_low_rh_day_enable: -1,
  alarm_over_rh_day_enable: -1,
  alarm_low_rh_night_enable: -1,
  alarm_over_rh_night_enable: -1,
  alarm_low_vpd_enable: -1,
  alarm_over_vpd_enable: -1,
  alarm_low_vpd_night_enable: -1,
  alarm_over_vpd_night_enable: -1,
  alarm_sensor_error_rht_enable: -1,
  alarm_sensor_error_co2_enable: -1,
  alarm_sensor_error_pressure_enable: -1,
  alarm_sensor_error_heating_mat_enable: -1,
  alarm_sensor_error_leaf_enable: -1,
  alarm_error_power_extender_enable: -1,
  alarm_sensor_error_intake_enable: -1,
  expected_power_extender_out_cnt: -1
};

export const advanced = {
  fan_ac_intake_turnoff: -1,
  fan_ac_exhaust_turnoff: -1,
  leaf_temp_diff: -1,
  hysteresis_co2: -1,
  reg_kd_pressure: -1,
  reg_kd_temp_air: -1,
  interval_ramp_temp_sp: -1,
  reg_type_humidifier: -1,
  hysteresis_humidifier: -1,
  humidifier_kd: -1,
  reg_type_dehumidifier: -1,
  hysteresis_dehumidifier: -1,
  offset_sp_dehumidifier: -1,
  hysteresis_heater: -1,
  hysteresis_chiller_low: -1,
  hysteresis_chiller_high: -1,
  co2_min_dose_ms: -1,
  intake_sens_min_diff_rh: -1,
  intake_sens_min_diff_temp: -1,
  intake_sens_retry_wait: -1,
  intake_sens_retry_duration: -1,
  rh_min_with_vpd: -1,
  rh_max_with_vpd: -1,
  lights_dim_over_temp_hyst: -1,
  lights_dim_over_temp_delay_sec: -1,
  offset_sp_humidifier: -1,
  dehumid_exhaust_threshold: -1,
  dehumid_exhaust_temp_untercut_max_allowed: -1,
  offset_sp_humidifier_night: -1,
  offset_sp_dehumidifier_night: -1
};

export const schedule_meta = {
  enable_scheduling: -1,
  name_short: -1,
  active_phase_cnt: -1,
  len_phase_1_days: -1,
  len_phase_2_days: -1,
  len_phase_3_days: -1,
  len_phase_4_days: -1,
  len_phase_5_days: -1,
  len_phase_6_days: -1,
  len_phase_7_days: -1,
  len_phase_8_days: -1,
  len_phase_9_days: -1,
  len_phase_10_days: -1,
  enable_smooth_change: -1,
  enable_humidity: -1,
  enable_temper_main: -1,
  enable_vpd: -1,
  enable_heater: -1,
  enable_heating_mat: -1,
  enable_co2_concentr: -1,
  enable_co2_temper: -1,
  enable_day_night: -1,
  enable_dim_ch_1: -1,
  enable_dim_ch_2: -1,
  enable_dim_ch_3: -1,
  enable_dim_ch_4: -1,
  enable_interval_1: -1,
  enable_interval_2: -1,
  enable_event_1: -1,
  enable_event_2: -1,
  enable_event_3: -1,
  enable_event_4: -1,
  enable_event_5: -1,
  enable_event_6: -1,
  enable_event_7: -1,
  enable_event_8: -1
};

// Converts timing settings and replaces all 0xffff values with -1
export function convert_settings(settings) {
  Object.keys(settings).forEach(function (category, _) {
    Object.keys(settings[category]).forEach(function (key) {
      if (settings[category][key] === 0xffff) settings[category][key] = -1;
    });
  });
  // Check for 0xffff values in scheduling phases:
  if ('schedule_values' in settings) {
    settings['schedule_values'].forEach((phase, index) => {
      Object.keys(phase).forEach((key) => {
        if (phase[key] === 0xffff || phase[key] === 0xfff)
          settings['schedule_values'][index][key] = -1;
      });
    });
  }
  return settings;
}
