import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import {
  multiOutMapping,
  multiIOHwTypeMappingSelect,
  multiIOHwTypeMappping,
  multiIOInfoTypeMapping
} from '../Constants/constants';

import { EditableLabel } from './EditableLabel';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main
  }
}));

export function MultiIOLabel({ func, info, info_type, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();

  if (!multiIOHwTypeMappping[func] || !multiIOHwTypeMappingSelect[multiIOHwTypeMappping[func]]) {
    return ''; // Return '' if hw_type is invalid or not found in the mapping
  }

  return (
    <>
      {info_type === 1 && (
        <>
          <label className={classes.label}>
            {t(multiIOInfoTypeMapping[info_type] ?? multiIOInfoTypeMapping['default'])}:{' '}
            {info_type === 1 && (info / 100).toFixed(2) + ' A'}
          </label>
          <br />
        </>
      )}
      <label>{t(multiIOHwTypeMappping[func] ?? multiIOHwTypeMappping['default'])}</label>
    </>
  );
}

export default function MultiOutLabel({ controller, parent, func, value, type, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  let value_field = '';
  if (controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)) {
    if (type === 1 || type === 2) {
      if (value === 0) {
        value_field = ' ' + t('off');
      } else if (value > 0) {
        value_field = ' ' + t('on');
      } else {
        value_field = '---';
      }
    } else if (type === 3) {
      if (value === 0) {
        value_field = ' ' + t('off');
      } else if (value > 0) {
        value_field = (value / 100).toFixed(1) + ' %';
      } else if (value > 20000) {
        value_field = '---' + ' %';
      } else {
        value_field = '---' + ' %';
      }
    } else if (type === 8) {
      if (value === 0) {
        value_field = ' ' + t('off');
      } else if (value > 0) {
        value_field = ' ' + t('on');
      } else {
        value_field = '---';
      }
    } else if (type === 9) {
      if (value === 0) {
        value_field = ' ' + t('off');
      } else if (value > 0) {
        value_field = (value / 100).toFixed(1) + ' %';
      } else if (value > 20000) {
        value_field = '---' + ' %';
      } else {
        value_field = '---' + ' %';
      }
    } else {
      value_field = '---';
    }
  } else {
    if (func !== 0 && func !== 1) {
      if (value === 0) {
        value_field = ' ' + t('off');
      } else if (value > 0) {
        value_field = ' ' + t('on');
      } else {
        value_field = '---';
      }
    } else {
      if (value === 0) {
        value_field = ' ' + t('off');
      } else if (value > 0) {
        value_field = (value / 100).toFixed(1) + ' %';
      } else if (value > 20000) {
        value_field = '---' + ' %';
      } else {
        value_field = '---' + ' %';
      }
    }
  }

  if (func === 19) {
    return (
      <label className={classes.label}>
        {t(multiOutMapping[func] ?? multiOutMapping['default'])}
      </label>
    );
  } else {
    return (
      <label className={classes.label}>
        {t(multiOutMapping[func] ?? multiOutMapping['default'])}: {value_field}
      </label>
    );
  }
}

function MultiOutContent({ controller, parent, index, memo, multi_out }) {
  let { t } = useTranslation();
  return (
    <React.Fragment>
      <Typography gutterBottom>
        {controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
          ? t('multifunctional_io') + ' ' + index
          : t('multifunctional_out') + ' ' + index}
      </Typography>
      {multi_out != null && (
        <>
          <br />
          Type: <span style={{ fontFamily: 'monospace' }}>{multi_out.type}</span>
        </>
      )}
      {memo !== -1 && memo !== '' ? '(' + memo + ')' : <br />}
    </React.Fragment>
  );
}

export function MultiOutHeading({ controller, parent, index, memo, content, multi_out }) {
  return (
    <Typography gutterBottom>
      <MultiOutContent
        controller={controller}
        parent={parent}
        index={index}
        memo={memo}
        multi_out={multi_out}
      />
    </Typography>
  );
}

export function MultiOutHeadingEditable({ controller, parent, index, memo, handleInput }) {
  let { t } = useTranslation();
  return (
    <EditableLabel
      label={
        controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
          ? t('multifunctional_io') + ' ' + index
          : t('multifunctional_out') + ' ' + index
      }
      id={'out_' + index + '_memo'}
      value={memo}
      handleInput={handleInput}
      prefix="("
      postfix=")"
    />
  );
}
