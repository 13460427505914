import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import {
  alarmMapping,
  multiOutMapping,
  multiIOHwTypeMappingSelect,
  multiIOHwTypeMappping,
  resolutions
} from '../Constants/constants';
import { getEventTimerIds, getIntervalTimerIds } from '../Utils/utils.js';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    minWidth: 120,
    color: theme.palette.fontcolor
  },
  label: {
    color: theme.palette.primary.main
  }
}));

export function EnableDisableSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('disable')}</MenuItem>
      <MenuItem value={1}>{t('enable')}</MenuItem>
    </Select>
  );
}
export function OffOnSettings({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('off')}</MenuItem>
      <MenuItem value={1}>{t('on')}</MenuItem>
    </Select>
  );
}

export function SwitchSettings({ value, onChange, ...other }) {
  let { t } = useTranslation();
  return (
    <FormControlLabel control={<Switch onChange={onChange} {...other} />} label={t('on_off')} />
  );
}

export function ApplyDeleteSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('auto_save_delete')}</MenuItem>
      <MenuItem value={1}>{t('auto_save_paste')}</MenuItem>
    </Select>
  );
}

export function DisplayTimeoutSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('displ_timeout_60')}</MenuItem>
      <MenuItem value={1}>{t('displ_timeout_100')}</MenuItem>
      <MenuItem value={2}>{t('displ_timeout_160')}</MenuItem>
      <MenuItem value={3}>{t('displ_timeout_on')}</MenuItem>
    </Select>
  );
}

export function EnableDayTimeDisableSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('disable')}</MenuItem>
      <MenuItem value={1}>{t('enable_at_daytime')}</MenuItem>
    </Select>
  );
}

export function EnableDisableDaytimeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('disable')}</MenuItem>
      <MenuItem value={1}>{t('enable_always')}</MenuItem>
      <MenuItem value={2}>{t('enable_at_daytime')}</MenuItem>
    </Select>
  );
}
export function AbsoluteRelativeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('relative')}</MenuItem>
      <MenuItem value={1}>{t('absolute')}</MenuItem>
    </Select>
  );
}

export function RoomScalseSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('small')}</MenuItem>
      <MenuItem value={1}>{t('medium')}</MenuItem>
      <MenuItem value={2}>{t('large')}</MenuItem>
    </Select>
  );
}

export function EcAcSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('show_ac_ec')}</MenuItem>
      <MenuItem value={1}>{t('show_ec')}</MenuItem>
      <MenuItem value={2}>{t('show_ac')}</MenuItem>
    </Select>
  );
}

export function DehumidificationModeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('off')}</MenuItem>
      <MenuItem value={1}>{t('low')}</MenuItem>
      <MenuItem value={2}>{t('medium')}</MenuItem>
      <MenuItem value={3}>{t('high')}</MenuItem>
    </Select>
  );
}

export function RegTypeCo2Select({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('fuzzy')}</MenuItem>
      <MenuItem value={1}>{t('P')}</MenuItem>
      <MenuItem value={2}>{t('PD')}</MenuItem>
    </Select>
  );
}

export function RegTypeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('PD')}</MenuItem>
      <MenuItem value={1}>{t('P')}</MenuItem>
    </Select>
  );
}

export function RegTypeHumidifierSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('PD')}</MenuItem>
      <MenuItem value={1}>{t('P')}</MenuItem>
      <MenuItem value={2}>{t('PID')}</MenuItem>
    </Select>
  );
}

export function TempFormatSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('celsius')}</MenuItem>
      <MenuItem value={1}>{t('fahrenheit')}</MenuItem>
    </Select>
  );
}

export function DateFormatSelect({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>YYYY-MM-DD</MenuItem>
      <MenuItem value={1}>DD.MM.YYYY</MenuItem>
    </Select>
  );
}

export function LanguageSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('english')}</MenuItem>
      <MenuItem value={1}>{t('german')}</MenuItem>
    </Select>
  );
}

export function AverageMinMaxSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('average')}</MenuItem>
      <MenuItem value={1}>{t('min')}</MenuItem>
      <MenuItem value={2}>{t('max')}</MenuItem>
    </Select>
  );
}

export function MultiOutSelect({ type, controller, parent, port, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();

  // TODO (fux): this seems not correct
  let not_pro = ![5055, 6000].includes(controller.type);

  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem disabled={type === 1 || type === 2} value={1}>
        {t('intake_fans_ac')}
      </MenuItem>
      <MenuItem disabled={type === 1 || type === 2} value={0}>
        {t('exhaust_fans_ac')}
      </MenuItem>

      {controller.type >= 6000 /*&& port.type == 'AO_VOLTAGE'*/ && (
        <MenuItem value={100}>{t('intake_fans_ec')}</MenuItem>
      )}
      {controller.type >= 6000 /*&& port.type == 'AO_VOLTAGE'*/ && (
        <MenuItem value={101}>{t('exhaust_fans_ec')}</MenuItem>
      )}

      <MenuItem value={2}>{t('humidifier')}</MenuItem>
      <MenuItem value={3}>{t('dehumidifier')}</MenuItem>
      <MenuItem value={4}>{t('lights')}</MenuItem>

      {controller.type >= 6000 /*&& port.type == 'A0_VOLTAGE'*/ && (
        <MenuItem value={102}>{t('dimm_lights')}</MenuItem>
      )}

      <MenuItem value={8}>{t('co2_valve')}</MenuItem>
      <MenuItem value={9}>{t('co2_generator')}</MenuItem>
      <MenuItem value={6}>{t('heating_mat')}</MenuItem>
      {!not_pro && (
        <MenuItem disabled={type === 0 || type === 2} value={10}>
          {t('heater')}
        </MenuItem>
      )}
      <MenuItem value={7}>{t('chiller')}</MenuItem>
      {/*!not_pro && <MenuItem value={21}>{t('interv_1_inv')}</MenuItem>}
      {!not_pro && <MenuItem value={22}>{t('interval_2')}</MenuItem>}
      {!not_pro && <MenuItem value={23}>{t('interv_2_inv')}</MenuItem>*/}

      {[
        ['event1', 11],
        ['event2', 12],
        ['event3', 13],
        ['event4', 14],
        ['event1+2', 15],
        ['event3+4', 16],
        ['event1+2+3', 17],
        ['event1+2+3+4', 18],

        ...(!not_pro
          ? [
              ['event5', 24],
              ['event6', 25],
              ['event7', 26],
              ['event8', 27],
              ['event5+6', 28],
              ['event1-8', 30]
            ]
          : [])
      ].map((i) => (
        <MenuItem value={i[1]}>{t(i[0])}</MenuItem>
      ))}

      {[
        ['interval', 5],
        ...(!not_pro
          ? [
              ['interv_1_inv', 21],
              ['interval_2', 22],
              ['interv_2_inv', 23]
            ]
          : [])
      ].map((i) => (
        <MenuItem value={i[1]}>{t(i[0])}</MenuItem>
      ))}

      <MenuItem value={19}>{t('disabled')}</MenuItem>
      <MenuItem value={20}>{t('out_en_always')}</MenuItem>
      {[
        ...Object.entries({
          21: 'irrigation_circulation_mix',
          22: 'irrigation_pump_1',
          23: 'irrigation_valve_main',
          24: 'irrigation_valve_refill_main',
          25: 'irrigation_tank_main_min',
          26: 'nutrient_pump_1',
          27: 'nutrient_pump_2',
          28: 'nutrient_pump_3'
        })
      ].map(([v, label]) => (
        <MenuItem value={v}>{t(label)}</MenuItem>
      ))}
    </Select>
  );
}

export function MultiIOSelect({ type, value, controller, parent, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  const hw_type = multiIOHwTypeMappping[type];

  if (!hw_type || !multiIOHwTypeMappingSelect[hw_type]) {
    return null; // Return null if hw_type is invalid or not found in the mapping
  }

  let not_pro = ![5055, 6000].includes(controller.type);

  let event_timer_ids = not_pro
    ? null
    : getEventTimerIds(
        controller.type < 6000 ? controller.settings['timing'] : parent.settings['timing']
      );
  let interval_timer_ids = not_pro
    ? null
    : getIntervalTimerIds(
        controller.type < 6000 ? controller.settings['timing'] : parent.settings['timing']
      );

  return (
    <React.Fragment>
      <label className={classes.label}>{t(hw_type)}</label>
      <br />
      <Select className={classes.selectEmpty} value={value} {...other}>
        {Object.values(
          multiIOHwTypeMappingSelect[hw_type]
            /* filter out items for interval & event timer for industrial, we use separate loop for these
				because they are dynamic. Make sure to not to filter out accidentally other stuff by using "int" as filter */
            .filter((v) => !(controller.type >= 6000 && (v.includes('int') || v.includes('event'))))
            .map((v) => (
              <MenuItem
                value={parseInt(
                  Object.keys(multiOutMapping).find((key) => multiOutMapping[key] === v)
                )}
              >
                {t(v)}
              </MenuItem>
            ))
        )}

        {controller.type >= 6000 &&
          ['digital_out', 'mixed_out'].includes(hw_type) &&
          interval_timer_ids.map((e) => (
            <MenuItem value={parent.settings.timing[`interval${e}_func`]}>
              {t(`interval`)} {e}
            </MenuItem>
          ))}

        {controller.type >= 6000 &&
          ['digital_out', 'mixed_out'].includes(hw_type) &&
          event_timer_ids.map((e) => (
            <MenuItem value={parent.settings.timing[`event${e}_func`]}>{t(`event${e}`)}</MenuItem>
          ))}
      </Select>
    </React.Fragment>
  );
}

export function EventGapSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('every_day')}</MenuItem>
      <MenuItem value={1}>{t('every_2_day')}</MenuItem>
      <MenuItem value={2}>{t('every_3_day')}</MenuItem>
      <MenuItem value={3}>{t('every_4_day')}</MenuItem>
      <MenuItem value={4}>{t('every_5_day')}</MenuItem>
      <MenuItem value={5}>{t('every_6_day')}</MenuItem>
      <MenuItem value={6}>{t('every_7_day')}</MenuItem>
      <MenuItem value={7}>{t('every_8_day')}</MenuItem>
      <MenuItem value={8}>{t('every_9_day')}</MenuItem>
      <MenuItem value={9}>{t('every_10_day')}</MenuItem>
      <MenuItem value={10}>{t('every_11_day')}</MenuItem>
      <MenuItem value={11}>{t('every_12_day')}</MenuItem>
      <MenuItem value={12}>{t('every_13_day')}</MenuItem>
      <MenuItem value={13}>{t('every_14_day')}</MenuItem>
      <MenuItem value={14}>{t('every_15_day')}</MenuItem>
      <MenuItem value={15}>{t('every_16_day')}</MenuItem>
      <MenuItem value={16}>{t('every_17_day')}</MenuItem>
      <MenuItem value={17}>{t('every_18_day')}</MenuItem>
      <MenuItem value={18}>{t('every_19_day')}</MenuItem>
      <MenuItem value={19}>{t('every_20_day')}</MenuItem>
      <MenuItem value={20}>{t('every_21_day')}</MenuItem>
      <MenuItem value={21}>{t('every_22_day')}</MenuItem>
      <MenuItem value={22}>{t('every_23_day')}</MenuItem>
      <MenuItem value={23}>{t('every_24_day')}</MenuItem>
      <MenuItem value={24}>{t('every_25_day')}</MenuItem>
      <MenuItem value={25}>{t('every_26_day')}</MenuItem>
      <MenuItem value={26}>{t('every_27_day')}</MenuItem>
      <MenuItem value={27}>{t('every_28_day')}</MenuItem>
    </Select>
  );
}

export function FirmwareSelect({ versions, contApiVersion, contFWVersion, ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={-1}>---</MenuItem>
      {versions.map((version, i) => (
        <MenuItem
          value={i}
          disabled={
            contApiVersion < 'AB' &&
            contApiVersion !== '#latest' &&
            contFWVersion.indexOf('0.00.18') !== 5
          }
        >
          {version.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export function AlarmSelect({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value="all">All</MenuItem>
      {Object.entries(alarmMapping).map(([alarm_id, alarm]) => (
        <MenuItem value={alarm_id}>{alarm.name}</MenuItem>
      ))}
    </Select>
  );
}

export function HistoryResSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={resolutions.recent}>{t('history_type_recent')}</MenuItem>
      <MenuItem value={resolutions.daily}>{t('history_type_daily')}</MenuItem>
      <MenuItem value={resolutions.full}>{t('history_type_full')}</MenuItem>
    </Select>
  );
}

export function TimeFormatSelect({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={'DD.MM.YYYY HH:mm:ss'}>17.01.2022 16:17:33</MenuItem>
      <MenuItem value={'YYYY-MM-DD HH:mm:ss'}>2022-01-17 16:17:33</MenuItem>
      <MenuItem value={'YYYY-MM-DD hh:mm:ss a'}>2022-01-17 04:17:33 PM</MenuItem>
    </Select>
  );
}

export function DelimiterSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={';'}>{t('semicolon')}</MenuItem>
      <MenuItem value={','}>{t('comma')}</MenuItem>
    </Select>
  );
}

export function DecimalSelect({ delimiter, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={','} disabled={delimiter === ','}>
        {t('comma')}
      </MenuItem>
      <MenuItem value={'.'}>{t('dot')}</MenuItem>
    </Select>
  );
}

export function TokenTypeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={'read'}>{t('Read')}</MenuItem>
    </Select>
  );
}

export function GenericSelect({ values, value, onChange }) {
  return (
    <Select value={value} onChange={onChange}>
      {values.map((v) => (
        <MenuItem value={v['value']}>{v['label']}</MenuItem>
      ))}
    </Select>
  );
}

export function DayWeekSelect({ use_week, ...other }) {
  const classes = useStyles();
  if (use_week) {
    return (
      <Select className={classes.selectEmpty} {...other}>
        {[...Array(56).keys()].map((week) => (
          <MenuItem value={7 * (1 + week)}>{1 + week}</MenuItem>
        ))}
      </Select>
    );
  } else {
    return (
      <Select className={classes.selectEmpty} {...other}>
        {[...Array(31).keys()].map((day) => (
          <MenuItem value={1 + day}>{1 + day}</MenuItem>
        ))}
      </Select>
    );
  }
}

export function DayWeekToggle({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={false}>Days</MenuItem>
      <MenuItem value={true}>Weeks</MenuItem>
    </Select>
  );
}

export function FlexSelect({ mapping, ...other }) {
  const classes = useStyles();
  let { t } = useTranslation();
  return (
    <Select className={classes.selectEmpty} {...other}>
      {Object.keys(mapping).map((k, i) => (
        <MenuItem value={i}>{t(mapping[k])}</MenuItem>
      ))}
    </Select>
  );
}
