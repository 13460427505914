import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import MultiOutLabel, { MultiOutHeading } from '../../FormWidgets/MultiOutLabel';
import { NormalValue, NegativeValue, FloatValue } from '../../FormWidgets/InfoValues';
import Desc from '../../FormWidgets/Description';
import { offOnMapping, dayNightCo2PreheatMapping } from '../../Constants/constants';
import { SettingGroup, ValueBox } from '../../FormWidgets/SettingGroup';
import { makeMultiOutObject } from '../../Utils/utils.js';

import { CustomizableComponent } from '../../FormWidgets/CustomizableComponent';

import { subscribe } from 'valtio';

function range(start, count, inc) {
  var foo = [];
  for (var i = 0; i < count; i += inc) foo.push(start + i);
  return foo;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  datasetText: {
    marginTop: 35,
    marginBottom: '-20px'
  },
  graphData: {
    width: '100%',
    height: '100px'
  },
  multiOutBox: {
    backgroundColor: theme.palette.innerContainer2.background
  }
}));

export default function ZoneOverview({ controller, parent, phase, ...others }) {
  const classes = useStyles();
  let { id } = useParams();
  let { t } = useTranslation();

  let time_points = 180;

  const [options] = useState({
    responsive: true,
    maintainAspectRatio: false,
    defaultColors: ['#95c11e'],
    elements: {
      point: { radius: 0 },
      line: { backgroundColor: '#95c11e', borderColor: '#95c11e', width: '100%' }
    },
    scales: {
      //yAxes: [{ gridLines: { drawBorder: false } }],
      //xAxes: [{ gridLines: { display: false } }],
      x: {
        grid: { display: false, drawBorder: false },
        ticks: { display: false }
      },
      y: {
        grid: { display: false, drawBorder: false },
        ticks: { display: false }
      }
    },
    plugins: { legend: { display: false } }
  });

  /*const [humidityData, setHumidityData] = useState({
    labels: [...Array(time_points).keys()],
    datasets: [
      {
        label: 'humidity',
        data: parent.infos.humidity_graph ?? [],
        hidden: false,
        tension: 0.5,
        unitPrefix: ' % RH'
      }
    ]
  });

  const [tempData, setTempData] = useState({
    labels: [...Array(time_points).keys()],
    datasets: [
      {
        label: 'temp',
        data: parent.infos.temp_graph ?? [],
        hidden: false,
        tension: 0.5,
        unitPrefix: ' °C'
      }
    ]
  });*/

  /*useEffect(() => {
    function updateHumidity() {
      const curHumidityData = humidityData;
      curHumidityData.datasets[0].data = parent.infos.humidity_graph;
      setHumidityData({
        labels: [...humidityData.labels],
        datasets: [...curHumidityData.datasets]
      });
    }

    function updateTemp() {
      const curTempData = tempData;
      curTempData.datasets[0].data = parent.infos.temp_graph;
      setTempData({ labels: [...tempData.labels], datasets: [...curTempData.datasets] });
    }
    updateHumidity();
    updateTemp();
    const unsubscribeHumidity = subscribe(parent.infos, updateHumidity);
    const unsubscribeTemp = subscribe(parent.infos, updateTemp);

    return () => {
      unsubscribeHumidity();
      unsubscribeTemp();
    };
  }, []);*/

  let multi_outs = makeMultiOutObject(controller, parent);

  return (
    <React.Fragment>
      {/* Climate Values */}
      {Object.values(controller.zones).map((zone) => (
        <>
          <CustomizableComponent
            id={`zoneoverview_${zone.id}`}
            controller={controller}
            parent={controller}
            zIndex="-2"
          >
            <Container>
              <h1>{zone.name}</h1>
              <CustomizableComponent
                controller={controller}
                parent={controller}
                id={`climate_values_${zone.id}`}
                zIndex="-1"
              >
                <SettingGroup label={t('climate_values')}>
                  <Grid container spacing={0.2}>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_humidity_sp_${zone.id}`}
                    >
                      <Grid item md={6} xs={12}>
                        <Box style={{ width: '100%' }}>
                          <ValueBox>
                            <Box justifyContent={'space-between'} display={'flex'}>
                              <Typography gutterBottom>
                                <Desc id={'humidity'} />
                              </Typography>

                              <Typography gutterBottom sx={{ paddingRight: 2 }}>
                                <Desc id={'info_humidity_sp'} />
                                {(zone.infos.info_humidity_sp ?? -1) !== -1
                                  ? parseFloat(zone.infos.info_humidity_sp / 10).toFixed(1)
                                  : 'n/a'}
                              </Typography>
                            </Box>

                            <FloatValue
                              name="humidity"
                              value={zone.infos.humidity ?? -1}
                              unit="% RH"
                            />

                            {/*{humidityData.datasets[0].data?.length > 5 && (
									  <>
										<Box className={classes.graphData}>
										  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
											<Typography>
											  {parseFloat(
												Math.max(...humidityData.datasets[0].data.filter(Boolean))
											  ).toFixed(1)}
											</Typography>
											<Typography>24h</Typography>
										  </Box>
										  <Line data={humidityData} options={options} />
										</Box>
										<Typography className={classes.datasetText} gutterBottom>
										  {parseFloat(
											Math.min(...humidityData.datasets[0].data.filter(Boolean))
										  ).toFixed(1)}
										</Typography>
									  </>
									)}*/}
                          </ValueBox>
                        </Box>
                      </Grid>
                    </CustomizableComponent>

                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_temp_sp_${zone.id}`}
                    >
                      <Grid item md={6} xs={12}>
                        <Box style={{ width: '100%' }}>
                          <ValueBox>
                            <Box justifyContent={'space-between'} display={'flex'}>
                              <Typography gutterBottom>
                                <Desc id={'temp'} />
                              </Typography>

                              <Typography gutterBottom>
                                <Desc id={'info_temp_sp'} />
                                {(zone.infos.info_temp_sp ?? -1 !== -1)
                                  ? parseFloat(zone.infos.info_temp_sp / 10).toFixed(1)
                                  : 'n/a'}
                              </Typography>
                            </Box>

                            <FloatValue name="temp" value={zone.infos.temp ?? -1} unit="°C" />

                            {/*{tempData.datasets[0].data?.length > 5 && (
								  <>
									<Box className={classes.graphData}>
									  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography>
										  {parseFloat(
											Math.max(...tempData.datasets[0].data.filter(Boolean))
										  ).toFixed(1)}
										</Typography>
										<Typography>24h</Typography>
									  </Box>
									  <Line data={tempData} options={options} />
									</Box>
									<Typography className={classes.datasetText} gutterBottom>
									  {parseFloat(Math.min(...tempData.datasets[0].data.filter(Boolean))).toFixed(
										1
									  )}
									</Typography>
								  </>
								)}*/}
                          </ValueBox>
                        </Box>
                      </Grid>
                    </CustomizableComponent>

                    {[5055, 5155, 6000].includes(controller.type) && (
                      <CustomizableComponent
                        controller={controller}
                        parent={zone}
                        id={`info_vpd_${zone.id}`}
                      >
                        <Grid item md={4} xs={12}>
                          <ValueBox>
                            <Typography gutterBottom>
                              <Desc id={'vpd'} />
                            </Typography>
                            <FloatValue name="vpd" value={zone.infos.vpd ?? -1} unit="hPa" />
                          </ValueBox>
                        </Grid>
                      </CustomizableComponent>
                    )}
                    {[5055, 5155, 6000].includes(controller.type) && (
                      <CustomizableComponent
                        controller={controller}
                        parent={zone}
                        id={`info_leaf_temp_${zone.id}`}
                      >
                        <Grid item md={4} xs={12}>
                          <ValueBox>
                            <Typography gutterBottom>
                              <Desc id={'temp_leaf_current'} />
                            </Typography>
                            <FloatValue
                              name="temp_leaf_current"
                              value={zone.infos.temp_leaf_current ?? -1}
                              unit="°C"
                            />
                          </ValueBox>
                        </Grid>
                      </CustomizableComponent>
                    )}
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_co2_${zone.id}`}
                    >
                      <Grid item md={4} xs={12}>
                        <ValueBox>
                          <Typography gutterBottom>
                            <Desc id={'co2'} />
                          </Typography>
                          <NormalValue name="co2" value={zone.infos.co2 ?? -1} unit="ppm" />
                        </ValueBox>
                      </Grid>
                    </CustomizableComponent>
                  </Grid>
                </SettingGroup>
              </CustomizableComponent>

              {[5155, 6000].includes(controller.type) && (
                <CustomizableComponent
                  controller={controller}
                  parent={zone}
                  id={`root_zone_hl" zIndex="-1_${zone.id}`}
                >
                  <SettingGroup label={t('root_zone_hl')}>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_vol_water_content_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'vol_water_content'} />
                        </Typography>
                        <FloatValue
                          name="vol_water_content"
                          value={zone.infos.vol_water_content ?? -1}
                          unit="%"
                          style={{ fontSize: '1.3rem' }}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_ec_root_zone_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'ec_root_zone'} />
                        </Typography>
                        <FloatValue
                          name="ec_root_zone"
                          value={zone.infos.ec_root_zone ?? -1}
                          unit="mS/cm"
                          style={{ fontSize: '1.3rem' }}
                          num_digits={2}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_temp_root_zone_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'temp_root_zone'} />
                        </Typography>
                        <FloatValue
                          name="temp_root_zone"
                          value={zone.infos.temp_root_zone ?? -1}
                          unit="°C"
                          style={{ fontSize: '1.3rem' }}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                  </SettingGroup>
                </CustomizableComponent>
              )}

              {[5155, 6000].includes(controller.type) && (
                <CustomizableComponent
                  controller={controller}
                  parent={zone}
                  id={`nutrients_hl" zIndex="-1_${zone.id}`}
                >
                  <SettingGroup label={t('nutrients_hl')}>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`ec_nutrient_solution_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'ec_nutrient_solution'} />
                        </Typography>
                        <FloatValue
                          name="ec_nutrient_solution"
                          value={zone.infos.ec_nutrient_solution ?? -1}
                          unit="mS/cm"
                          style={{ fontSize: '1.3rem' }}
                          num_digits={2}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`ph_nutrient_solution_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'ph_nutrient_solution'} />
                        </Typography>
                        <FloatValue
                          name="ph_nutrient_solution"
                          value={zone.infos.ph_nutrient_solution ?? -1}
                          unit="pH"
                          style={{ fontSize: '1.3rem' }}
                          num_digits={2}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`temp_nutrient_solution_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'temp_nutrient_solution'} />
                        </Typography>
                        <FloatValue
                          name="temp_nutrient_solution"
                          value={zone.infos.temp_nutrient_solution ?? -1}
                          unit="°C"
                          style={{ fontSize: '1.3rem' }}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                  </SettingGroup>
                </CustomizableComponent>
              )}

              {/* More */}
              <CustomizableComponent
                controller={controller}
                parent={zone}
                id={`more" zIndex="-1_${zone.id}`}
              >
                <SettingGroup label={t('more')}>
                  {[5055, 5155, 6000].includes(controller.type) && (
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`rh_intake_current_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'rh_intake_current'} show="humidity_intake" />
                        </Typography>
                        <FloatValue
                          name="rh_intake_current"
                          value={zone.infos.rh_intake_current ?? -1}
                          unit="% RH"
                          style={{ fontSize: '1.3rem' }}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                  )}

                  {[5055, 5155, 6000].includes(controller.type) && (
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`temp_intake_current_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'temp_intake_current'} show="temp_intake" />
                        </Typography>
                        <FloatValue
                          name="temp_intake_current"
                          value={zone.infos.temp_intake_current ?? -1}
                          unit="°C"
                          style={{ fontSize: '1.3rem' }}
                        />
                      </ValueBox>
                    </CustomizableComponent>
                  )}

                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`temp_heating_mat_${zone.id}`}
                  >
                    <ValueBox>
                      <Typography gutterBottom>
                        <Desc id={'temp_heating_mat'} />
                      </Typography>
                      <FloatValue
                        name="temp_heating_mat"
                        value={zone.infos.temp_heating_mat ?? -1}
                        unit="°C"
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`neg_pressure_${zone.id}`}
                  >
                    <ValueBox>
                      <Typography gutterBottom>
                        <Desc id={'neg_pressure'} />
                      </Typography>
                      <NegativeValue
                        name="neg_pressure"
                        value={zone.infos.neg_pressure ?? -1}
                        unit="Pa"
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`info_lights_${zone.id}`}
                  >
                    <ValueBox>
                      <Typography gutterBottom>
                        <Desc id={'info_lights'} />
                      </Typography>
                      <NormalValue
                        name="info_lights"
                        value={zone.infos.info_lights ?? -1}
                        unit="%"
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`info_interval_timer_${zone.id}`}
                  >
                    <ValueBox style={{ minWidth: '250px' }}>
                      <Typography gutterBottom>
                        <Desc id={'info_interval_timer'} />
                      </Typography>
                      <label name="info_interval_timer">
                        <NormalValue
                          name="onoffswitch"
                          value={t(
                            offOnMapping[zone.infos.info_interval_timer_active ?? -1] ??
                              offOnMapping['default']
                          )}
                          unit=""
                          style={{ fontSize: '1.3rem' }}
                        />{' '}
                        in
                        <NormalValue
                          name="intervaltimer"
                          value={zone.infos.info_interval_timer ?? -1}
                          unit=""
                          style={{ fontSize: '1.3rem' }}
                        />
                      </label>
                    </ValueBox>
                  </CustomizableComponent>
                  {[5055, 5155, 6000].includes(controller.type) && (
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`info_interval_2_timer_${zone.id}`}
                    >
                      <ValueBox style={{ minWidth: '250px' }}>
                        <Typography gutterBottom>
                          <Desc id={'info_interval_2_timer'} />
                        </Typography>
                        <label name="info_interval_2_timer">
                          <NormalValue
                            name="onoffswitch"
                            value={t(
                              offOnMapping[zone.infos.info_interval_2_timer_active ?? -1] ??
                                offOnMapping['default']
                            )}
                            unit=""
                            style={{ fontSize: '1.3rem' }}
                          />{' '}
                          in
                          <NormalValue
                            name="intervaltimer"
                            value={zone.infos.info_interval_2_timer}
                            unit=""
                            style={{ fontSize: '1.3rem' }}
                          />
                        </label>
                      </ValueBox>
                    </CustomizableComponent>
                  )}

                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`time_${zone.id}`}
                  >
                    <ValueBox style={{ minWidth: '150px' }}>
                      <Typography gutterBottom>
                        <Desc id={'time'} />
                      </Typography>
                      <NormalValue
                        name="time"
                        value={zone.infos.time ?? ''}
                        unit=""
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`date_${zone.id}`}
                  >
                    <ValueBox>
                      <Typography gutterBottom>
                        <Desc id={'date'} />
                      </Typography>
                      <NormalValue
                        name="date"
                        value={zone.infos.date ?? ''}
                        unit=""
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`phase_${zone.id}`}
                  >
                    <ValueBox>
                      <Typography gutterBottom>
                        <Desc id={'phase'} />
                      </Typography>
                      <label name="phase">
                        <NormalValue
                          name="phasevalue"
                          value={t(dayNightCo2PreheatMapping[zone.infos.phase ?? -1])}
                          unit=""
                          style={{ fontSize: '1.3rem' }}
                        />
                      </label>
                    </ValueBox>
                  </CustomizableComponent>

                  {[5055, 5155, 6000].includes(controller.type) && (
                    <CustomizableComponent
                      controller={controller}
                      parent={zone}
                      id={`temp_heater_setpoint_info_${zone.id}`}
                    >
                      <ValueBox>
                        <Typography gutterBottom>
                          <Desc id={'temp_heater_setpoint_info'} />
                        </Typography>

                        {(zone.infos.temp_heater_setpoint_info ?? -1) > 49 && (
                          <FloatValue
                            name="temp_heater_setpoint_info"
                            value={zone.infos.temp_heater_setpoint_info ?? -1}
                            unit="°C"
                            style={{ fontSize: '1.3rem' }}
                          />
                        )}
                        {(zone.infos.temp_heater_setpoint_info ?? -1) <= 49 && (
                          <NormalValue
                            name="phasevalue"
                            value={t('off')}
                            unit=""
                            style={{ fontSize: '1.3rem' }}
                          />
                        )}
                      </ValueBox>
                    </CustomizableComponent>
                  )}

                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`zone.settings.ec_fans_intake_${zone.id}`}
                  >
                    <ValueBox style={{ minWidth: '200px' }}>
                      <Typography gutterBottom>
                        <Desc id={'zone.settings.ec_fans_intake'} />
                      </Typography>
                      <NormalValue
                        name="zone.settings.ec_fans_intake_out"
                        value={zone.settings.ec_fans_intake_out ?? -1}
                        unit="% "
                        style={{ fontSize: '1.3rem' }}
                      />
                      <NormalValue
                        name="zone.settings.ec_fans_intake_rpm"
                        value={zone.settings.ec_fans_intake_rpm ?? -1}
                        unit="rpm"
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                  <CustomizableComponent
                    controller={controller}
                    parent={zone}
                    id={`zone.settings.ec_fans_exhaust_${zone.id}`}
                  >
                    <ValueBox style={{ minWidth: '200px' }}>
                      <Typography gutterBottom>
                        <Desc id={'zone.settings.ec_fans_exhaust'} />
                      </Typography>
                      <NormalValue
                        name="zone.settings.ec_fans_exhaust_out"
                        value={zone.settings.ec_fans_exhaust_out ?? -1}
                        unit="% "
                        style={{ fontSize: '1.3rem' }}
                      />
                      <NormalValue
                        name="zone.settings.ec_fans_exhaust_rpm"
                        value={zone.settings.ec_fans_exhaust_rpm ?? -1}
                        unit="rpm"
                        style={{ fontSize: '1.3rem' }}
                      />
                    </ValueBox>
                  </CustomizableComponent>
                </SettingGroup>
              </CustomizableComponent>

              <CustomizableComponent
                controller={controller}
                parent={zone}
                id={`info_multi_outs" zIndex="-1_${zone.id}`}
              >
                <SettingGroup
                  label={
                    t('multifunctional_outputs_heading') +
                    ' (' +
                    Object.values(multi_outs).length +
                    ')'
                  }
                >
                  {/*{controller.type >= 6000 ? (
				  <>
					{Object.keys(multi_outs).map((slave) => (
					  <div
						className={classes.multiOutBox}
						style={{ padding: '1em', width: '100%', marginBottom: '0.5em' }}
					  >
						<span style={{ fontSize: '1.5em' }}>
						  {' '}
						  Slave: <span style={{ fontFamily: 'monospace' }}>{slave.toString()}</span>
						</span>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						  {Object.values(multi_outs[slave]).map((mo) => (
							<ValueBox>
							  <MultiOutHeading index={mo.id} memo={mo.memo ?? ''} />
							  <MultiOutLabel func={mo.func ?? ''} value={mo.state ?? ''} />
							</ValueBox>
						  ))}
						</div>
					  </div>
					))}
				  </>
				) : (
				  <>
					{Object.values(multi_outs).map((mo) => (
					  <ValueBox key={'multi_out_elem_' + mo.id}>
						<MultiOutHeading index={mo.id} memo={mo.memo ?? ''} />
						<MultiOutLabel func={mo.func ?? ''} value={mo.state ? ''} />
					  </ValueBox>
					))}
				  </>
				)}*/}
                </SettingGroup>
              </CustomizableComponent>
            </Container>
          </CustomizableComponent>
        </>
      ))}
    </React.Fragment>
  );
}
