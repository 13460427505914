import getStateManager from '../StateManager.js';
import { useSnapshot } from 'valtio';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useRef, cloneElement } from 'react';

export function CustomizableComponent({ id, controller, parent, children, zIndex }) {
  const store = getStateManager();
  const stateManager = useSnapshot(store);

  console.log('customizable', id, parent);

  let editMode = stateManager.editMode;
  let visible = stateManager.profile[controller.id]?.[parent.id]?.[id] ?? true;

  const toggle = () => {
    stateManager.toggleCustomComponentVisibility(controller, parent, id);
  };

  const containerRef = useRef();

  return (
    <>
      {editMode ? (
        <>
          {cloneElement(children, { ref: containerRef, reference: containerRef })}

          {containerRef.current &&
            (visible ? (
              <div
                style={{
                  zIndex: 100 + Number(zIndex ?? 0),
                  margin: 0,
                  padding: 0,
                  position: 'absolute',
                  top: containerRef.current.offsetTop,
                  left: containerRef.current.offsetLeft,
                  width: containerRef.current.offsetWidth,
                  height: containerRef.current.offsetHeight
                }}
              >
                <VisibilityIcon onClick={toggle} />
              </div>
            ) : (
              <div
                style={{
                  zIndex: 100 + Number(zIndex ?? 0),
                  backdropFilter: 'blur(1px)',
                  position: 'absolute',
                  top: containerRef.current.offsetTop,
                  left: containerRef.current.offsetLeft,
                  width: containerRef.current.offsetWidth,
                  height: containerRef.current.offsetHeight,
                  backgroundColor: 'rgba(255,255,255,0.5)'
                }}
              >
                <VisibilityOffIcon onClick={toggle} />
              </div>
            ))}
        </>
      ) : (
        <>{visible && children}</>
      )}
    </>
  );
}
