import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import { statusColors } from '../Constants/constants';

import MultiOutLabel, { MultiOutHeading, MultiIOLabel } from '../FormWidgets/MultiOutLabel';
import { NormalValue, NegativeValue, FloatValue } from '../FormWidgets/InfoValues';
import Desc from '../FormWidgets/Description';
import {
  offOnMapping,
  dayNightCo2PreheatMapping,
  onOffMapping,
  irrigationStateMapping,
  irrigationPhaseMapping,
  irrigationErrorMapping,
  hvacStatusMapping
} from '../Constants/constants';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';
import { makeMultiOutObject, makeSlaveInfoObject } from '../Utils/utils.js';

import { CustomizableComponent } from '../FormWidgets/CustomizableComponent';

import { subscribe } from 'valtio';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  datasetText: {
    marginTop: 35,
    marginBottom: '-20px'
  },
  graphData: {
    width: '100%',
    height: '100px'
  },
  multiOutBox: {
    backgroundColor: theme.palette.innerContainer2.background
  }
}));

export default function Info({ controller, parent, phase, ...others }) {
  const classes = useStyles();
  let { t } = useTranslation();

  let time_points = 180;

  const [options] = useState({
    responsive: true,
    maintainAspectRatio: false,
    defaultColors: ['#95c11e'],
    elements: {
      point: { radius: 0 },
      line: { backgroundColor: '#95c11e', borderColor: '#95c11e', width: '100%' }
    },
    scales: {
      //yAxes: [{ gridLines: { drawBorder: false } }],
      //xAxes: [{ gridLines: { display: false } }],
      x: {
        grid: { display: false, drawBorder: false },
        ticks: { display: false }
      },
      y: {
        grid: { display: false, drawBorder: false },
        ticks: { display: false }
      }
    },
    plugins: { legend: { display: false } }
  });

  const [humidityData, setHumidityData] = useState({
    labels: [...Array(time_points).keys()],
    datasets: [
      {
        label: 'humidity',
        data: parent.infos.humidity_graph ?? [],
        hidden: false,
        tension: 0.5,
        unitPrefix: ' % RH'
      }
    ]
  });

  const [tempData, setTempData] = useState({
    labels: [...Array(time_points).keys()],
    datasets: [
      {
        label: 'temp',
        data: parent.infos.temp_graph ?? [],
        hidden: false,
        tension: 0.5,
        unitPrefix: ' °C'
      }
    ]
  });

  const [id, setId] = useState(parent.id);
  useEffect(() => {
    setId(parent.id);
  }, [parent]);

  useEffect(() => {
    function updateHumidity() {
      const curHumidityData = humidityData;
      curHumidityData.datasets[0].data = parent.infos.humidity_graph;
      setHumidityData({
        labels: [...humidityData.labels],
        datasets: [...curHumidityData.datasets]
      });
    }

    function updateTemp() {
      const curTempData = tempData;
      curTempData.datasets[0].data = parent.infos.temp_graph;
      setTempData({ labels: [...tempData.labels], datasets: [...curTempData.datasets] });
    }
    updateHumidity();
    updateTemp();
    const unsubscribeHumidity = subscribe(parent.infos, updateHumidity);
    const unsubscribeTemp = subscribe(parent.infos, updateTemp);

    return () => {
      unsubscribeHumidity();
      unsubscribeTemp();
    };
  }, [id]);

  let cur_values = parent.infos;
  let ec_fans = parent.settings.ec_fans;

  let multi_outs = makeMultiOutObject(controller, parent);
  let slaves_infos = makeSlaveInfoObject(controller, parent);

  return (
    <React.Fragment>
      {/* Climate Values */}

      <CustomizableComponent
        controller={controller}
        parent={parent}
        id="climate_values"
        zIndex="-1"
      >
        <SettingGroup controller={controller} id={'climate_values'}>
          <Grid container spacing={0.2}>
            <CustomizableComponent controller={controller} parent={parent} id="info_humidity_sp">
              <Grid item md={6} xs={12}>
                <Box style={{ width: '100%' }}>
                  <ValueBox controller={controller} id={'info_humidity'}>
                    <Box justifyContent={'space-between'} display={'flex'}>
                      <Typography gutterBottom>
                        <Desc id={'humidity'} />
                      </Typography>

                      <Typography gutterBottom sx={{ paddingRight: 2 }}>
                        <Desc id={'info_humidity_sp'} />
                        {(cur_values.info_humidity_sp ?? -1) !== -1
                          ? parseFloat(cur_values.info_humidity_sp / 10).toFixed(1)
                          : 'n/a'}
                      </Typography>
                    </Box>

                    <FloatValue name="humidity" value={cur_values.humidity ?? -1} unit="% RH" />

                    {humidityData.datasets[0].data?.length > 5 && (
                      <>
                        <Box className={classes.graphData}>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>
                              {parseFloat(
                                Math.max(...humidityData.datasets[0].data.filter(Boolean))
                              ).toFixed(1)}
                            </Typography>
                            <Typography>24h</Typography>
                          </Box>
                          <Line data={humidityData} options={options} />
                        </Box>
                        <Typography className={classes.datasetText} gutterBottom>
                          {parseFloat(
                            Math.min(...humidityData.datasets[0].data.filter(Boolean))
                          ).toFixed(1)}
                        </Typography>
                      </>
                    )}
                  </ValueBox>
                </Box>
              </Grid>
            </CustomizableComponent>

            <CustomizableComponent controller={controller} parent={parent} id="info_temp_sp">
              <Grid item md={6} xs={12}>
                <Box style={{ width: '100%' }}>
                  <ValueBox controller={controller} id={'info_temp'}>
                    <Box justifyContent={'space-between'} display={'flex'}>
                      <Typography gutterBottom>
                        <Desc id={'temp'} />
                      </Typography>

                      <Typography gutterBottom>
                        <Desc id={'info_temp_sp'} />
                        {(cur_values.info_temp_sp ?? -1 !== -1)
                          ? parseFloat(cur_values.info_temp_sp / 10).toFixed(1)
                          : 'n/a'}
                      </Typography>
                    </Box>

                    <FloatValue name="temp" value={cur_values.temp ?? -1} unit="°C" />

                    {tempData.datasets[0].data?.length > 5 && (
                      <>
                        <Box className={classes.graphData}>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>
                              {parseFloat(
                                Math.max(...tempData.datasets[0].data.filter(Boolean))
                              ).toFixed(1)}
                            </Typography>
                            <Typography>24h</Typography>
                          </Box>
                          <Line data={tempData} options={options} />
                        </Box>
                        <Typography className={classes.datasetText} gutterBottom>
                          {parseFloat(
                            Math.min(...tempData.datasets[0].data.filter(Boolean))
                          ).toFixed(1)}
                        </Typography>
                      </>
                    )}
                  </ValueBox>
                </Box>
              </Grid>
            </CustomizableComponent>

            <CustomizableComponent controller={controller} parent={parent} id="info_vpd">
              <Grid item md={4} xs={12}>
                <ValueBox controller={controller} id="info_vpd">
                  <Typography gutterBottom>
                    <Desc id={'vpd'} />
                  </Typography>
                  <FloatValue name="vpd" value={cur_values.vpd ?? -1} unit="hPa" />
                </ValueBox>
              </Grid>
            </CustomizableComponent>

            <CustomizableComponent controller={controller} parent={parent} id="info_leaf_temp">
              <Grid item md={4} xs={12}>
                <ValueBox controller={controller} id="info_temp_leaf_current">
                  <Typography gutterBottom>
                    <Desc id={'temp_leaf_current'} />
                  </Typography>
                  <FloatValue
                    name="temp_leaf_current"
                    value={cur_values.temp_leaf_current ?? -1}
                    unit="°C"
                  />
                </ValueBox>
              </Grid>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="info_co2">
              <Grid item md={4} xs={12}>
                <ValueBox controller={controller} id={'info_co2'}>
                  <Typography gutterBottom>
                    <Desc id={'co2'} />
                  </Typography>
                  <NormalValue name="co2" value={cur_values.co2 ?? -1} unit="ppm" />
                </ValueBox>
              </Grid>
            </CustomizableComponent>
          </Grid>
        </SettingGroup>
      </CustomizableComponent>

      <CustomizableComponent controller={controller} parent={parent} id="root_zone_hl" zIndex="-1">
        <SettingGroup controller={controller} id={'root_zone_hl'}>
          <CustomizableComponent
            controller={controller}
            parent={parent}
            id="info_vol_water_content"
          >
            <ValueBox controller={controller} id={'info_vol_water_content'}>
              <Typography gutterBottom>
                <Desc id={'vol_water_content'} />
              </Typography>
              <FloatValue
                name="vol_water_content"
                value={cur_values.vol_water_content ?? -1}
                unit="%"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="info_ec_root_zone">
            <ValueBox controller={controller} id={'info_ec_root_zone'}>
              <Typography gutterBottom>
                <Desc id={'ec_root_zone'} />
              </Typography>
              <FloatValue
                name="ec_root_zone"
                value={cur_values.ec_root_zone ?? -1}
                unit="mS/cm"
                style={{ fontSize: '1.3rem' }}
                num_digits={2}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="info_temp_root_zone">
            <ValueBox controller={controller} id={'info_temp_root_zone'}>
              <Typography gutterBottom>
                <Desc id={'temp_root_zone'} />
              </Typography>
              <FloatValue
                name="temp_root_zone"
                value={cur_values.temp_root_zone ?? -1}
                unit="°C"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
        </SettingGroup>
      </CustomizableComponent>

      <CustomizableComponent controller={controller} parent={parent} id="nutrients_hl" zIndex="-1">
        <SettingGroup controller={controller} id={'nutrients_hl'}>
          <CustomizableComponent controller={controller} parent={parent} id="ec_nutrient_solution">
            <ValueBox controller={controller} id={'info_ec_nutrient_solution'}>
              <Typography gutterBottom>
                <Desc id={'ec_nutrient_solution'} />
              </Typography>
              <FloatValue
                name="ec_nutrient_solution"
                value={cur_values.ec_nutrient_solution ?? -1}
                unit="mS/cm"
                style={{ fontSize: '1.3rem' }}
                num_digits={2}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="ph_nutrient_solution">
            <ValueBox controller={controller} id={'info_ph_nutrient_solution'}>
              <Typography gutterBottom>
                <Desc id={'ph_nutrient_solution'} />
              </Typography>
              <FloatValue
                name="ph_nutrient_solution"
                value={cur_values.ph_nutrient_solution ?? -1}
                unit="pH"
                style={{ fontSize: '1.3rem' }}
                num_digits={2}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent
            controller={controller}
            parent={parent}
            id="temp_nutrient_solution"
          >
            <ValueBox controller={controller} id={'info_temp_nutrient_solution'}>
              <Typography gutterBottom>
                <Desc id={'temp_nutrient_solution'} />
              </Typography>
              <FloatValue
                name="temp_nutrient_solution"
                value={cur_values.temp_nutrient_solution ?? -1}
                unit="°C"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
        </SettingGroup>
      </CustomizableComponent>

      <CustomizableComponent
        controller={controller}
        parent={parent}
        id="irrigation_info_hl"
        zIndex="-1"
      >
        <SettingGroup controller={controller} id={'irrigation_info_hl'}>
          <CustomizableComponent controller={controller} parent={parent} id="irrigation_state">
            <ValueBox controller={controller} id={'info_irrigation_state'}>
              <Typography gutterBottom>
                <Desc id={'irrigation_state'} />
              </Typography>
              <NormalValue
                name="irrigation_state"
                value={t(
                  irrigationStateMapping[cur_values.irrigation_state ?? -1] ??
                    irrigationStateMapping['default']
                )}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="irrigation_phase_info">
            <ValueBox controller={controller} id={'info_irrigation_phase_info'}>
              <Typography gutterBottom>
                <Desc id={'irrigation_phase_info'} />
              </Typography>
              <NormalValue
                name="irrigation_phase_info"
                value={t(
                  irrigationPhaseMapping[cur_values.irrigation_phase_info ?? -1] ??
                    irrigationPhaseMapping['default']
                )}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="irrigation_err">
            <ValueBox controller={controller} id={'info_irrigation_err'}>
              <Typography gutterBottom>
                <Desc id={'irrigation_err'} />
              </Typography>
              <NormalValue
                name="irrigation_err"
                value={t(
                  irrigationErrorMapping[cur_values.irrigation_err ?? -1] ??
                    irrigationErrorMapping['default']
                )}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
        </SettingGroup>
      </CustomizableComponent>

      {cur_values.show_hvac_info === 1 && (
        <CustomizableComponent
          controller={controller}
          parent={parent}
          id="hvac_info_hl"
          zIndex="-1"
        >
          <SettingGroup controller={controller} id={'hvac_info_hl'}>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_status">
              <ValueBox controller={controller} id={'info_hvac_status'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_status'} />
                </Typography>
                <NormalValue
                  name="hvac_status"
                  value={t(
                    hvacStatusMapping[cur_values.hvac_status ?? -1] ?? hvacStatusMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_rh_info">
              <ValueBox controller={controller} id={'info_hvac_rh_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_rh_info'} />
                </Typography>
                <FloatValue
                  name="hvac_rh_info"
                  value={cur_values.hvac_rh_info ?? -1}
                  unit="% RH"
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_temp_info">
              <ValueBox controller={controller} id={'info_hvac_temp_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_temp_info'} />
                </Typography>
                <FloatValue
                  name="hvac_temp_info"
                  value={cur_values.hvac_temp_info ?? -1}
                  unit="°C"
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent
              controller={controller}
              parent={parent}
              id="hvac_compress_status"
            >
              <ValueBox controller={controller} id={'info_hvac_compress_status'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_compress_status'} />
                </Typography>
                <NormalValue
                  name="hvac_compress_status"
                  value={t(
                    onOffMapping[cur_values.hvac_compress_status ?? -1] ?? onOffMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_info">
              <ValueBox controller={controller} id={'info_hvac_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_info'} />
                </Typography>
                <NormalValue
                  name="hvac_info"
                  value={cur_values.hvac_info ?? -1}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
          </SettingGroup>
        </CustomizableComponent>
      )}

      {cur_values.show_hvac_info_2 === 1 && (
        <CustomizableComponent
          controller={controller}
          parent={parent}
          id="hvac_info_hl_2"
          zIndex="-1"
        >
          <SettingGroup controller={controller} id={'hvac_info_hl_2'}>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_status">
              <ValueBox controller={controller} id={'info_hvac_status'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_status'} />
                </Typography>
                <NormalValue
                  name="hvac_status"
                  value={t(
                    hvacStatusMapping[cur_values.hvac_status_2 ?? -1] ??
                      hvacStatusMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_rh_info">
              <ValueBox controller={controller} id={'info_hvac_rh_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_rh_info'} />
                </Typography>
                <FloatValue
                  name="hvac_rh_info"
                  value={cur_values.hvac_rh_info_2 ?? -1}
                  unit="% RH"
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_temp_info">
              <ValueBox controller={controller} id={'info_hvac_temp_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_temp_info'} />
                </Typography>
                <FloatValue
                  name="hvac_temp_info"
                  value={cur_values.hvac_temp_info_2 ?? -1}
                  unit="°C"
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent
              controller={controller}
              parent={parent}
              id="hvac_compress_status"
            >
              <ValueBox controller={controller} id={'info_hvac_compress_status'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_compress_status'} />
                </Typography>
                <NormalValue
                  name="hvac_compress_status"
                  value={t(
                    onOffMapping[cur_values.hvac_compress_status_2 ?? -1] ?? onOffMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_info">
              <ValueBox controller={controller} id={'info_hvac_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_info'} />
                </Typography>
                <NormalValue
                  name="hvac_info"
                  value={cur_values.hvac_info_2 ?? -1}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
          </SettingGroup>
        </CustomizableComponent>
      )}

      {cur_values.show_hvac_info_3 === 1 && (
        <CustomizableComponent
          controller={controller}
          parent={parent}
          id="hvac_info_hl_3"
          zIndex="-1"
        >
          <SettingGroup controller={controller} id={'hvac_info_hl_3'}>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_status">
              <ValueBox controller={controller} id={'info_hvac_status'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_status'} />
                </Typography>
                <NormalValue
                  name="hvac_status"
                  value={t(
                    hvacStatusMapping[cur_values.hvac_status_3 ?? -1] ??
                      hvacStatusMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_rh_info">
              <ValueBox controller={controller} id={'info_hvac_rh_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_rh_info'} />
                </Typography>
                <FloatValue
                  name="hvac_rh_info"
                  value={cur_values.hvac_rh_info_3 ?? -1}
                  unit="% RH"
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_temp_info">
              <ValueBox controller={controller} id={'info_hvac_temp_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_temp_info'} />
                </Typography>
                <FloatValue
                  name="hvac_temp_info"
                  value={cur_values.hvac_temp_info_3 ?? -1}
                  unit="°C"
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent
              controller={controller}
              parent={parent}
              id="hvac_compress_status"
            >
              <ValueBox controller={controller} id={'info_hvac_compress_status'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_compress_status'} />
                </Typography>
                <NormalValue
                  name="hvac_compress_status"
                  value={t(
                    onOffMapping[cur_values.hvac_compress_status_3 ?? -1] ?? onOffMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
            <CustomizableComponent controller={controller} parent={parent} id="hvac_info">
              <ValueBox controller={controller} id={'info_hvac_info'}>
                <Typography gutterBottom>
                  <Desc id={'hvac_info'} />
                </Typography>
                <NormalValue
                  name="hvac_info"
                  value={cur_values.hvac_info_3 ?? -1}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </ValueBox>
            </CustomizableComponent>
          </SettingGroup>
        </CustomizableComponent>
      )}

      {/* More */}
      <CustomizableComponent controller={controller} parent={parent} id="more" zIndex="-1">
        <SettingGroup label={t('more')}>
          <CustomizableComponent controller={controller} parent={parent} id="rh_intake_current">
            <ValueBox controller={controller} id={'info_rh_intake_current'}>
              <Typography gutterBottom>
                <Desc id={'rh_intake_current'} show="humidity_intake" />
              </Typography>
              <FloatValue
                name="rh_intake_current"
                value={cur_values.rh_intake_current ?? -1}
                unit="% RH"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>

          <CustomizableComponent controller={controller} parent={parent} id="temp_intake_current">
            <ValueBox controller={controller} id={'info_temp_intake_current'}>
              <Typography gutterBottom>
                <Desc id={'temp_intake_current'} show="temp_intake" />
              </Typography>
              <FloatValue
                name="temp_intake_current"
                value={cur_values.temp_intake_current ?? -1}
                unit="°C"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>

          <CustomizableComponent controller={controller} parent={parent} id="temp_heating_mat">
            <ValueBox controller={controller} id={'info_temp_heating_mat'}>
              <Typography gutterBottom>
                <Desc id={'temp_heating_mat'} />
              </Typography>
              <FloatValue
                name="temp_heating_mat"
                value={cur_values.temp_heating_mat ?? -1}
                unit="°C"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="neg_pressure">
            <ValueBox controller={controller} id={'info_neg_pressure'}>
              <Typography gutterBottom>
                <Desc id={'neg_pressure'} />
              </Typography>
              <NegativeValue
                name="neg_pressure"
                value={cur_values.neg_pressure ?? -1}
                unit="Pa"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="info_lights">
            <ValueBox controller={controller} id={'info_lights'}>
              <Typography gutterBottom>
                <Desc id={'info_lights'} />
              </Typography>
              <NormalValue
                name="info_lights"
                value={cur_values.info_lights ?? -1}
                unit="%"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="info_interval_timer">
            <ValueBox
              controller={controller}
              id={'info_interval_timer'}
              style={{ minWidth: '250px' }}
            >
              <Typography gutterBottom>
                <Desc id={'info_interval_timer'} />
              </Typography>
              <label name="info_interval_timer">
                <NormalValue
                  name="onoffswitch"
                  value={t(
                    offOnMapping[cur_values.info_interval_timer_active ?? -1] ??
                      offOnMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />{' '}
                in
                <NormalValue
                  name="intervaltimer"
                  value={cur_values.info_interval_timer ?? -1}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </label>
            </ValueBox>
          </CustomizableComponent>

          <CustomizableComponent controller={controller} parent={parent} id="info_interval_2_timer">
            <ValueBox
              controller={controller}
              id={'info_interval_2_timer'}
              style={{ minWidth: '250px' }}
            >
              <Typography gutterBottom>
                <Desc id={'info_interval_2_timer'} />
              </Typography>
              <label name="info_interval_2_timer">
                <NormalValue
                  name="onoffswitch"
                  value={t(
                    offOnMapping[cur_values.info_interval_2_timer_active ?? -1] ??
                      offOnMapping['default']
                  )}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />{' '}
                in
                <NormalValue
                  name="intervaltimer"
                  value={cur_values.info_interval_2_timer}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </label>
            </ValueBox>
          </CustomizableComponent>

          <CustomizableComponent controller={controller} parent={parent} id="time">
            <ValueBox controller={controller} id={'info_time'} style={{ minWidth: '150px' }}>
              <Typography gutterBottom>
                <Desc id={'time'} />
              </Typography>
              <NormalValue
                name="time"
                value={cur_values.time ?? ''}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="date">
            <ValueBox controller={controller} id={'info_date'}>
              <Typography gutterBottom>
                <Desc id={'date'} />
              </Typography>
              <NormalValue
                name="date"
                value={cur_values.date ?? ''}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="phase">
            <ValueBox controller={controller} id={'info_phase'}>
              <Typography gutterBottom>
                <Desc id={'phase'} />
              </Typography>
              <label name="phase">
                <NormalValue
                  name="phasevalue"
                  value={t(dayNightCo2PreheatMapping[cur_values.phase ?? -1])}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              </label>
            </ValueBox>
          </CustomizableComponent>

          <CustomizableComponent
            controller={controller}
            parent={parent}
            id="temp_heater_setpoint_info"
          >
            <ValueBox controller={controller} id={'info_temp_heater_setpoint_info'}>
              <Typography gutterBottom>
                <Desc id={'temp_heater_setpoint_info'} />
              </Typography>

              {(cur_values.temp_heater_setpoint_info ?? -1) > 49 && (
                <FloatValue
                  name="temp_heater_setpoint_info"
                  value={cur_values.temp_heater_setpoint_info ?? -1}
                  unit="°C"
                  style={{ fontSize: '1.3rem' }}
                />
              )}
              {(cur_values.temp_heater_setpoint_info ?? -1) <= 49 && (
                <NormalValue
                  name="phasevalue"
                  value={t('off')}
                  unit=""
                  style={{ fontSize: '1.3rem' }}
                />
              )}
            </ValueBox>
          </CustomizableComponent>

          <CustomizableComponent controller={controller} parent={parent} id="ec_fans_intake">
            <ValueBox
              controller={controller}
              id={'info_ec_fans_intake'}
              style={{ minWidth: '200px' }}
            >
              <Typography gutterBottom>
                <Desc id={'ec_fans_intake'} />
              </Typography>
              <NormalValue
                name="ec_fans_intake_out"
                value={ec_fans.ec_fans_intake_out ?? -1}
                unit="% "
                style={{ fontSize: '1.3rem' }}
              />
              <NormalValue
                name="ec_fans_intake_rpm"
                value={ec_fans.ec_fans_intake_rpm ?? -1}
                unit="rpm"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
          <CustomizableComponent controller={controller} parent={parent} id="ec_fans_exhaust">
            <ValueBox
              controller={controller}
              id={'info_ec_fans_exhaust'}
              style={{ minWidth: '200px' }}
            >
              <Typography gutterBottom>
                <Desc id={'ec_fans_exhaust'} />
              </Typography>
              <NormalValue
                name="ec_fans_exhaust_out"
                value={ec_fans.ec_fans_exhaust_out ?? -1}
                unit="% "
                style={{ fontSize: '1.3rem' }}
              />
              <NormalValue
                name="ec_fans_exhaust_rpm"
                value={ec_fans.ec_fans_exhaust_rpm ?? -1}
                unit="rpm"
                style={{ fontSize: '1.3rem' }}
              />
            </ValueBox>
          </CustomizableComponent>
        </SettingGroup>
      </CustomizableComponent>

      <CustomizableComponent
        controller={controller}
        parent={parent}
        id="info_multi_outs"
        zIndex="-1"
      >
        <SettingGroup
          label={
            controller.type === 6000 ||
            (controller.type === 5155 && parent.settings.multi_io != null)
              ? t('multifunctional_ios_heading') +
                ' (' +
                Object.values(parent.settings.multi_io?.ios ?? {}).length +
                ')'
              : t('multifunctional_outputs_heading') + ' (' + Object.values(multi_outs).length + ')'
          }
        >
          {controller.type >= 6000 ||
          (controller.type === 5155 && parent.settings.multi_io != null) ? (
            <>
              {Object.keys(multi_outs).map((slave) => (
                <div
                  className={classes.multiOutBox}
                  style={{ padding: '1em', width: '100%', marginBottom: '0.5em' }}
                >
                  <span style={{ fontSize: '1.5em' }}>
                    {' '}
                    {t('slave_address_hl')}{' '}
                    <span style={{ fontFamily: 'monospace' }}>{slave.toString()}</span>
                    {
                      <CircleIcon
                        style={{
                          color:
                            controller.type === 5155 && parent.settings.multi_io != null
                              ? (() => {
                                  const matchingSlave = Object.values(slaves_infos).find(
                                    (si) => si.address === parseInt(slave)
                                  );
                                  return matchingSlave
                                    ? statusColors[
                                        matchingSlave.status === 0
                                          ? 'issues'
                                          : matchingSlave.status === 1
                                            ? 'good'
                                            : matchingSlave.status === 2
                                              ? 'issues'
                                              : matchingSlave.status === 3
                                                ? 'issues'
                                                : matchingSlave.status === 4
                                                  ? 'bad'
                                                  : 'issues'
                                      ]
                                    : statusColors['unknown'];
                                })()
                              : // ToDo: This is a temporary logic placeholder. We need to get the slave status properly for the Industrial Pro (6000).
                                statusColors[
                                  slave.connected === true
                                    ? 'good'
                                    : slave.connected === false
                                      ? 'bad'
                                      : 'issues'
                                ],
                          height: '1.0em',
                          width: '1.5em',
                          borderRadius: '50%',
                          display: 'inline-block'
                        }}
                      />
                    }
                  </span>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {Object.values(multi_outs[slave]).map(
                      (mo) =>
                        mo.func !== 19 &&
                        mo.func != null &&
                        mo.type !== 0 && (
                          <ValueBox controller={controller} id={mo.id}>
                            <MultiOutHeading
                              controller={controller}
                              parent={parent}
                              index={mo.id}
                              memo={mo.memo ?? ''}
                            />
                            <MultiOutLabel
                              controller={controller}
                              parent={parent}
                              func={mo.func ?? ''}
                              value={mo.state ?? ''}
                              type={mo.type ?? ''}
                            />
                            <br />
                            <MultiIOLabel
                              func={mo.type ?? ''}
                              info={mo.info ?? ''}
                              info_type={mo.info_type ?? ''}
                            />
                          </ValueBox>
                        )
                    )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {Object.values(multi_outs).map(
                (mo) =>
                  mo.func !== 19 &&
                  mo.func != null && (
                    <ValueBox
                      controller={controller}
                      id={'multi_out_elem_' + mo.id}
                      key={'multi_out_elem_' + mo.id}
                    >
                      <MultiOutHeading
                        controller={controller}
                        parent={parent}
                        index={mo.id}
                        memo={mo.memo ?? ''}
                      />
                      <MultiOutLabel
                        controller={controller}
                        parent={parent}
                        func={mo.func ?? ''}
                        value={mo.state ?? ''}
                      />
                    </ValueBox>
                  )
              )}
            </>
          )}
        </SettingGroup>
      </CustomizableComponent>
    </React.Fragment>
  );
}
